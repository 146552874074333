





















































































































import Vue from "vue";
import { Component, Prop } from 'vue-property-decorator'
import { FollowedOrg } from '../model/FollowedOrg'
import { Organization } from '../model/Organization'
import { isNotificationSupported } from '../util/Notification'
import { mapState } from 'vuex'

@Component({
  computed: {
    ...mapState([
      'followedOrgs'
    ])
  }
})
export default class OrgBanner extends Vue {

  @Prop({ required: true })
  value!: Organization

  @Prop({ required: false, default: false })
  small!: boolean

  @Prop({ required: false, default: false })
  hideLinks!: boolean

  private subscribing = false

  private followedOrgs!: Array<FollowedOrg>

  private get isFollowed (): boolean {
    return this.followedOrgs.some(org => org.id === this.value.id)
  }

  private get isSubscribed (): boolean {
    const followedOrg = this.followedOrgs.find(org => org.id === this.value.id)
    return followedOrg != null && followedOrg.subscribed
  }

  private get phoneText() {
    if(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
     return "Call"
   } else {
     return this.value.phoneNumber
   }
  }

  private get orgLocationString() {
    if (this.value.location.city && this.value.location.region) {
      return this.value.location.city + ", " + this.value.location.region
    }
    else if (this.value.location.region) {
      return this.value.location.region
    }

    return ""
  }

  private get orgLinks() {
    var urls: any[]= []

    urls.push({"url": `https://www.beerscovery.com/org/${this.value.id}/menus`, "name": "Menu(s)", "icon": "mdi-list-box-outline"})

    if (this.value.websiteUrl) {
      urls.push({"url": this.value.websiteUrl, "name": "Website", "icon": "mdi-web"})
    }

    if (this.value.storeUrl) {
      urls.push({"url": this.value.storeUrl, "name": "Shop Beer", "icon": "mdi-glass-mug-variant"})
    }

    if (this.value.merchUrl) {
      urls.push({"url": this.value.merchUrl, "name": "Shop Merch", "icon": "mdi-tshirt-crew"})
    }

    if (this.value.instagramHandle) {
      urls.push({"url": `https://www.instagram.com/${this.value.instagramHandle}`, "name": "Instagram", "icon": "mdi-instagram"})
    }

    urls.push({"url": `https://www.beerscovery.com/org/${this.value.id}/links`, "name": "Link Feed", "icon": "mdi-link"})

    return urls
  }

  private toggleFollow () {
    if (this.isFollowed) {
      this.$store.commit('unfollowOrg', this.value.id)
    } else {
      this.$store.commit('followOrg', this.value.id)
    }
  }

  private async toggleSubscribe () {
    this.subscribing = true
    if (this.isSubscribed) {
      await this.$store.dispatch('unsubscribeOrg', this.value.id)
    } else {
      await this.$store.dispatch('subscribeOrg', this.value.id)
    }
    this.subscribing = false
  }

  private get showSubscribe () {
    return isNotificationSupported() && this.isFollowed
  }

  private get cardColor () {
    if (this.value.style?.eventCardColor != null) {
      return this.value.style.eventCardColor
    } else {
      return 'primary'
    }
  }

  private get textColor () {
    if (this.value.style?.eventTextColor != null) {
      return this.value.style.eventTextColor
    } else {
      return '#ffffff'
    }
  }

  private get plainPhoneNumber () {
    if (this.value.phoneNumber != null) {
      return this.value.phoneNumber.replace(/\D/g, '')
    } else {
      return null
    }
  }

}
